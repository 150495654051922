import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "habitats-brand-voice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#habitats-brand-voice",
        "aria-label": "habitats brand voice permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Habitat's brand voice`}</h2>
    <p>{`Like a good sofa, Habitat's brand voice is comfy and colourful.`}</p>
    <p>{`That’s the feeling they want their customers to get from their writing. That Habitat is there for them, easy to settle into and read. But with a splash of colour that brightens their day.`}</p>
    <h3>{`Comfy`}</h3>
    <p><strong parentName="p">{`Warm. Friendly. Welcoming. And easy to read.`}</strong></p>
    <p>{`Comfy is all about understanding the reader. They’re busy. They’ve got their own style. But they’re also excited.`}</p>
    <h3>{`Colourful`}</h3>
    <p><strong parentName="p">{`Playful, bright and full of life.`}</strong></p>
    <p>{`Colourful is all about playing around with language. Make it a joy to read. So show some personality. Be creative. And have fun writing it.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "how-to-sound-comfy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-sound-comfy",
        "aria-label": "how to sound comfy permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to sound comfy`}</h2>
    <p>{`Keep it short, and watch out for repetition.`}</p>
    <Guideline mdxType="Guideline">
  <Do title="Like this" mdxType="Do">
    From leather sofas to contemporary styles, your sofa search starts here.
  </Do>
  <Dont title="Not this" mdxType="Dont">
    You're looking for the sofa of the century - the comfy spot where you'll
    watch movies and inhale popcorn. Well, you’ve come to the right place. From
    leather sofas to modern velvet and contemporary styles, your sofa search
    starts and ends here.
  </Dont>
    </Guideline>
    <p>{`Use natural, chatty language, like how you’d speak to a friend.`}</p>
    <Guideline mdxType="Guideline">
  <Do title="Like this" mdxType="Do">Tell us about yourself.</Do>
  <Dont title="Not this" mdxType="Dont">Provide the following details to continue.</Dont>
    </Guideline>
    <p>{`Use words that feel warm and comfortable without being overbearing.`}</p>
    <Guideline mdxType="Guideline">
  <Do title="Like this" mdxType="Do">
    <div>Tell us about yourself</div>
    <div>
      <strong>Already registered?</strong>
      If you’ve got an account, you can sign in here.
    </div>
  </Do>
  <Dont title="Not this" mdxType="Dont">
    <div>Provide the following details to continue</div>
    <div>
      <strong>You’re back!</strong>
      We’re so glad to see you again. Sign in here.
    </div>
  </Dont>
    </Guideline>
    <p>{`Go easy on the sell. Think about what the reader really cares about, showing off features but avoiding empty phrases.`}</p>
    <Guideline mdxType="Guideline">
  <Do title="Like this" mdxType="Do">
    <strong>Do you want marketing from us?</strong>
    We’ll send you news on our latest collections and offers.
  </Do>
  <Dont title="Not this" mdxType="Dont">
    <strong>Opt in to marketing</strong>
    As a valued Habitat customer, we don’t want you to miss out on everything we’ve
    got to offer!
  </Dont>
    </Guideline>
    <p>{`When you’re writing FAQs or tricky messages, use even more natural language and keep it short and simple.`}</p>
    <Guideline mdxType="Guideline">
  <Do title="Like this" mdxType="Do">
    <strong>How long does it take?</strong>
    About 10 working days, if it’s something small. And we’ll pop you a text the
    night before to make sure you’re in.
  </Do>
  <Dont title="Not this" mdxType="Dont">
    <strong>What are the estimated delivery times?</strong>
    Delivery typically takes up to 10 working days for smaller items. You will receive
    a message in advance of delivery.
  </Dont>
    </Guideline>
    <h2 {...{
      "id": "how-to-sound-colourful",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-sound-colourful",
        "aria-label": "how to sound colourful permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to sound colourful`}</h2>
    <p>{`Up the rhythm with short sentences.`}</p>
    <Guideline mdxType="Guideline">
  <Do title="Like this" mdxType="Do">
    Sign in for more. We can show you past and current orders.
  </Do>
  <Dont title="Not this" mdxType="Dont">
    Sign in to your account for more information on your past and current
    orders.
  </Dont>
    </Guideline>
    <p>{`Give your writing personality and introduce unexpected moments. You can play with sound using alliteration or rhyme, or use unexpected phrases. But make sure it’s appropriate for the message.`}</p>
    <Guideline mdxType="Guideline">
  <Do title="Like this" mdxType="Do">
    <strong>A little leeway during lockdown</strong>
    Anything you bought since 18 October 2020, you have until 24 January 2021 to
    return.
  </Do>
  <Dont title="Not this" mdxType="Dont">
    <strong>Amends to our returns policy during lockdown</strong>
    Anything you bought since 18 October 2020, you have until 24 January 2021 to
    return.
  </Dont>
    </Guideline>
    <Guideline mdxType="Guideline">
  <Do title="Like this" mdxType="Do">Ta-dah! Your £10 voucher’s been applied.</Do>
  <Dont title="Not this" mdxType="Dont">Your £10 voucher has been applied.</Dont>
    </Guideline>
    <p>{`Use writing devices like puns or rhetorical questions, but be careful not to overdo it. Not every sentence has to dazzle your reader.`}</p>
    <Guideline mdxType="Guideline">
  <Do title="Like this" mdxType="Do">
    <strong>Want it tomorrow?</strong>
    Good job we offer next day delivery.
  </Do>
  <Dont title="Not this" mdxType="Dont">
    <strong>Want it tomorrow?</strong>
    Course you do! Just tick that next day delivery. Bish bash bosh!
  </Dont>
    </Guideline>
    <p>{`Use words that shout ‘can do’.`}</p>
    <Guideline mdxType="Guideline">
  <Do title="Like this" mdxType="Do">
    <strong>Want to return it?</strong>
    We’ve got easy at-home collection and speedy refunds.
  </Do>
  <Dont title="Not this" mdxType="Dont">
    <strong>Returns information</strong>
    We can collect from your home, and refunds are processed quickly.
  </Dont>
    </Guideline>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  Our copy team are on hand to answers questions or help with your next project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      